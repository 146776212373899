import { IGetQuery } from 'ets-fe-ng-sdk';

export interface ISaveDocumentMetadata {
  companyCode?: string;
  refCat?: ERefCat;
  refNo?: string;
  category?: string;
  fileName?: string;
}

export interface IDocumentMetadata extends ISaveDocumentMetadata {
  docKey: string;
  docCode: string;
  category: string;
  subCategory: string;
  fileName: string;
  createdOn: string;
  id: number;
}

export type IDocumentMetadataSQ = IGetQuery<{
  docKey: string;
  company: string;
  fileName: string;
  refCat: ERefCat;
  refNo: string;
  ria: string;

  category: string;
  docCode: string;
}>;

export enum ERefCat {
  candidate = 'CD',
  company = 'COM',
  exam = 'EX',
  agent = 'AGT',
  user = 'USR',
  course = 'COU',
  help = 'HLP',
  adminHelp = 'ADMIN_HLP',
}
